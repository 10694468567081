<template>
  <v-layout column>
    <DodoTopToolBar
      :back_action="'/customerService2/channel/list'"
      :back_title="currentService.name"
      :back_type="currentService.type"
      :selected_view="0"
      :isSearchable="false"
      :isFilterable="true"
      :searchFunction="getConversations"
      :numOfFilters="filtersCount"
      @filter="filterMessages()"
      @clearFilters="clearFilters()"
    >
      <template v-slot:filters>
        <form>
          <v-layout pt-3 column>
            <div>
              <v-text-field
                v-model="filters.search"
                label="Procurar"
                placeholder="Nome, cpf, crm ..."
                height="42"
              >
              </v-text-field>
            </div>
            <div>
              <v-autocomplete
                v-model="filters.selectStatus"
                :items="statusOptions"
                item-value="value"
                item-text="text"
                label="Status"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="remove(data, 'selectStatus', true)"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
            <div>
              <v-autocomplete
                :items="
                  [
                    {
                      'admin':true,
                      'auth_id':null,
                      'created_at':null,
                      'email':'rasa@rasa',
                      'id':'rasa',
                      'in_vacation':false,
                      'name':'Rasa',
                      'profile_photo_url':'icon-rasa.png',
                      'updated_at': null
                    }
                  ].concat(usersList)"
                height="42"
                chips
                label="Atendentes"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.selectedAttendantsId"
              >
                <template v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar>
                      <img :src="verifyImg(data.item.profile_photo_url)" />
                    </v-avatar>
                    {{ data.item.name || "rasa"}}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <img :src="verifyImg(data.item.profile_photo_url)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name || 'rasa'">
                      </v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.email || 'rasa@rasa'">
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </div>
            <div px-2 sm5>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="computedDate(filters.startDate)"
                    clearable
                    label="Data inicial"
                    readonly
                    height="42"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.startDate"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div px-2 sm5>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="computedDate(filters.endDate)"
                    clearable
                    label="Data final"
                    readonly
                    v-on="on"
                    height="42"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.endDate"
                  @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div>
              <v-autocomplete
                v-model="filters.intent"
                :items="intentsUntilNow"
                label="Intenções reconhecidas"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="remove(data, 'intent')"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
            <div>
             <v-autocomplete
                v-model="filters.initiative_id"
                :items="filteredInitiatives"
                item-value="id"
                item-text="key"
                label="Iniciativa disparada"
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="remove(data, 'initiative_id', false)"
                  >
                    {{ data.item.key }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
            <div>
             <v-autocomplete
                v-model="filters.quick_reply_id"
                :items="filteredQuickReplies"
                item-value="id"
                item-text="key"
                label="Macro disparada"
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="remove(data, 'quick_reply_id', false)"
                  >
                    {{ data.item.key }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
            <div>
              <v-checkbox
                v-model="filters.bad_classification"
                label="Possui classificações erradas"
              ></v-checkbox>
            </div>
            <div>
              <v-checkbox
                v-model="filters.have_user_interation"
                label="Possui resposta do cliente"
              ></v-checkbox>
            </div>
          </v-layout>
        </form>
      </template>
    </DodoTopToolBar>

    <v-card class="conversation">
      <v-data-table
        v-model="itemsSelected"
        :headers="headers"
        fixed-header
        :items="messages"
        :items-per-page="25"
        :item-key="'id'"
        class="elevation-1 secondary-background"
        :footer-props="{
          'items-per-page-options': [25, 50, 100],
          'items-per-page-text': 'Resultados por página',
        }"
        :server-items-length="totalItems"
        :loading="loadingCondition()"
        :options.sync="options"
        @update:options="getConversations(null)"
      >
        <template v-slot:loading>
          <v-skeleton-loader type="table-row-divider@25"></v-skeleton-loader>
        </template>
        <template v-slot:item="props">
          <tr @click="openConversation(props.item.id)" class="double-click">
            <td class="text-center">
              <v-chip
                v-if="props.item.crm_ref"
                target="blank"
                small
                outlined
                class="ml-0"
                color="teal"
              >
                <span>{{ props.item.crm_ref }}</span>
              </v-chip>
            </td>
            <td>
              <v-list class="ignore-vue-based" style="background: unset" p0>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      props.item.customer.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      props.item.customer.document
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    class="status-conversation"
                    :class="props.item.status"
                    v-on="on"
                    >{{
                      props.item.status
                        | firstLetter
                    }}</span
                  >
                </template>
                <span>
                  {{ translateStatus(props.item.status) }}
                </span>
              </v-tooltip>
            </td>
            <td class="content" style="">
              <v-layout
                align-center
                v-if="props.item.last_message && props.item.last_message.content"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-icon
                        v-if="props.item.last_message.content_type == 'text' && props.item.last_message.type == 'received'"
                        :size="21"
                        class="ml-1 mr-4"
                        color="red lighten-1"
                      >
                        comment</v-icon
                      >
                      <v-icon
                        v-if="
                          props.item.last_message.content_type != 'text'
                        "
                        :size="21"
                        class="ml-1 mr-4"
                        color="red lighten-1"
                      >
                        attachment</v-icon
                      >
                      <v-icon
                        v-if="
                          props.item.last_message.content_type == 'text' && 
                          props.item.last_message.user_id &&
                          props.item.last_message.type == 'sent'"
                        color="light-green"
                        :size="21"
                        class="ml-1 mr-4"
                        >chat
                      </v-icon>
                      <v-icon
                        v-if="
                          props.item.last_message.content_type == 'quick_reply'
                        "
                        color="light-green"
                        :size="21"
                        class="ml-1 mr-4"
                      >
                        quickreply</v-icon
                      >
                      <v-icon
                        v-if="
                          props.item.last_message.content_type == 'text' && 
                          !props.item.last_message.user_id && 
                          props.item.last_message.type == 'sent'"
                        color="light-green"
                        :size="21"
                        class="ml-1 mr-4"
                        >textsms
                      </v-icon>
                      <v-icon
                        v-if="props.item.last_message.content_type == 'initiative'"
                        color="light-green"
                        :size="21"
                        class="ml-1 mr-4"
                      >
                        announcement</v-icon
                      >
                    </div>
                  </template>
                  <span>{{
                    translateMessageType(
                      props.item.last_message.content_type, 
                      props.item.last_message.type, 
                      props.item.last_message.user_id
                    )
                  }}</span>
                </v-tooltip>
                <v-layout
                  v-if="
                    props.item.last_message.content.payload &&
                    props.item.last_message.content.payload.body &&
                    props.item.last_message.content.payload.body.processed
                  "
                >
                  <span
                    v-html="
                      truncate(
                        props.item.last_message.content.payload.body.processed,
                        120
                      )
                    "
                  ></span>
                </v-layout>
                <v-layout
                  align-center
                  v-else-if="
                    props.item.last_message.content &&
                    props.item.last_message.content.text
                  "
                  class="original"
                >
                  <span>{{
                    props.item.last_message.content.text | truncate(120)
                  }}</span>
                </v-layout>
                <div v-else-if="props.item.last_message.content_type == 'ptt'">
                  <AudioPlayer
                    :file="props.item.last_message.content.url"
                  />
                </div>
                <div
                  v-else-if="
                    props.item.last_message.content_type == 'image'
                  "
                >
                  <v-tooltip
                    right
                    class="img-tool"
                    content-class="image-tooltip"
                    fixed
                    style="opacity: 1 !important"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on" style="cursor: pointer">
                        <v-icon>image</v-icon>
                        <span>Imagem</span>
                      </div>
                    </template>
                    <v-img
                      class="img-tool"
                      :lazy-src="
                        props.item.last_message.content.url
                      "
                      height="700px"
                      contain="true"
                      :src="props.item.last_message.content.url"
                    >
                    </v-img>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="
                    props.item.last_message.content_type == 'document'
                  "
                  class="d-flex align-center"
                >
                  <v-icon color="red">picture_as_pdf</v-icon>
                  <span class="mx-2">{{
                    props.item.last_message.content.caption
                  }}</span>
                  <v-btn
                    icon
                    outlined
                    small
                    download
                    :href="props.item.last_message.content.url"
                    target="_blank"
                  >
                    <v-icon small>file_download</v-icon>
                  </v-btn>
                </div>
                <v-layout
                  v-else-if="
                    props.item.last_message.type == 'sticker'
                  "
                  column
                >
                  <div
                    style="
                      width: 40px;
                      max-height: 40px;
                      overflow: hidden;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      position: relative;
                      max-width: 100%;
                      margin-bottom: 10px;
                      border-radius: 4px;
                    "
                  >
                    <viewer
                      style="display: flex"
                      :images="[
                        props.item.last_message.content.url,
                      ]"
                      :options="{ movable: true, title: false, navbar: false }"
                    >
                      <img
                        v-for="src in [
                          props.item.last_message.content.url,
                        ]"
                        :src="src"
                        :key="src"
                        style="width: 100%; cursor: pointer"
                      />
                    </viewer>
                  </div>
                </v-layout>
                <span v-else class="original"> </span>
                <v-spacer></v-spacer>
              </v-layout>
            </td>
          
            <td>
              <v-chip v-if="props.item.user">
                <v-avatar left size="20px">
                  <img :src="props.item.user.profile_photo_url" />
                </v-avatar>
                {{ props.item.user.name | firstName2 }}
              </v-chip>
              <v-chip v-else>
                <v-avatar left size="20px">
                  <img src="icon-rasa.png" alt="avatar"/>
                </v-avatar>
                  Rasa
              </v-chip>
              <!-- <span v-else style="font-size: medium">Não atribuído</span>  -->
            </td>
            <td>
              {{ (props.item.updated_at + "Z") | moment("D/MM/YYYY HH:mm:ss") }}
            </td>
            <td>
              {{ (props.item.created_at + "Z") | moment("D/MM/YYYY HH:mm:ss") }}
            </td>
            <td>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="tertiary-background">
                  <v-list-item @click="openConversation(props.item.id)">
                    <v-list-item-title>Abrir</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="props.item.status != 'CLOSED'"
                    @click="closeSession(props.item)"
                  >
                    <v-list-item-title>Encerrar sessão</v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

  </v-layout>
</template>

<script>
import DodoTopToolBar from "../components/TopToolBar";
import AudioPlayer from '@/components/Common/AudioPlayer'
import moment from "moment";
import lodash from "lodash";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import environment from "@/assets/js/environment";
import { utils } from "@/assets/js/utils";

export default {
  data() {
    return {
      filtersApllied: [],
      filtersCount: 0,
      isMounted: false,
      itemsSelected: [],
      headers: [
        { text: "CRM", align: "center", value: "crm_ref", width: "10%" , sortable: false },
        { text: "Conversa", align: "left", value: "title", width: "10%" , sortable: false },
        { text: "Status", align: "left", value: "status", width: "10%" , sortable: false },
        { text: "Ultima Mensagem", align: "left", value: "title", width: "46%" , sortable: false },
        { text: "Responsável", align: "left", value: "user_id", width: "10%" , sortable: false },
        { text: "Atualizado em", align: "left", value: "updated_at", width: "7%" , sortable: false },
        { text: "Criado em", align: "left", value: "created_at", width: "7%" },
      ],
      options: {},
      statusOptions: [
        { text: "Gerenciado pelo Rasa", value: "AUTO" },
        { text: "Pendente", value: "PENDING" },
        { text: "Aguardando cliente", value: "WAITING_CUSTOMER" },
        { text: "Aguardando tratamento", value: "WAITING_TREATMENT" },
        { text: "Fechando", value: "CLOSING" },
        { text: "Fechado", value: "CLOSED" },
      ],
      menu1: false,
      menu2: false,
      filteredQuickReplies: [],
      filteredInitiatives: [],
    };
  },
  components: {
    DodoTopToolBar,
    AudioPlayer,
  },
  async created() {
    this.clearFilters()
    let queryParams = this.$route.query;
    await this.getQueryParams(queryParams);
    await this.getIntents()
    this.isMounted = true;
    this.filtersCount = this.filtersApllied.length;
    this.filteredQuickReplies = this.quickReplyList.filter(el => el.service_id == this.currentService.id && el.active)
    this.filteredInitiatives = this.initiativesList.filter(el => el.service_id == this.currentService.id && el.active)
  },
  methods: {
    truncate: utils.truncate,
    ...mapActions("rasaConversationList", {
      getConversationList: "getAll",
      getIntents: "getIntents",
    }),
    ...mapActions("rasaSessions", {
      basicChangeSessionStatus: "basicChangeSessionStatus",
    }),
    closeSession(item) {
      this.basicChangeSessionStatus({'session': item, 'new_status':'CLOSED'})
      for (let idx in this.messages) {
        if (this.messages[idx].id == item.id) {
          this.messages[idx].status = 'CLOSED'
          return
        }
      }
    },

    computedDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
    createSearchParams() {
      let params = {
        limit: this.options.itemsPerPage,
        page: this.options.page,
        service_id: this.$route.params.service_id,
      };
      if (this.options.sortBy && this.options.sortBy.length > 0) {
        params.order =
          (this.options.sortDesc[0] ? "-" : "") + this.options.sortBy[0];
      }
      if (
        this.filters.selectedAttendantsId &&
        this.filters.selectedAttendantsId.length > 0
      ) {
        if (this.filters.selectedAttendantsId[0] == 0) {
          this.filters.selectedAttendantsId.shift();
        }
        params.user_id = this.filters.selectedAttendantsId.join(",");
      }
      if (this.filters.selectStatus && this.filters.selectStatus.length > 0) {
        if (typeof this.filters.selectStatus == "string")
          params.status = this.filters.selectStatus;
        else params.status = this.filters.selectStatus.join(",");
      }
      if (this.filters.startDate) {
        params["start"] = this.filters.startDate;
      }
      if (this.filters.endDate) {
        params["end"] = this.filters.endDate;
      }
      if (this.filters.search) {
        params.search = this.filters.search;
      }
      if (this.filters.customer_id) {
        params.customer_id = this.filters.customer_id;
      }
      if (this.filters.bad_classification) {
        params.bad_classification = this.filters.bad_classification;
      }
      if (this.filters.intent && this.filters.intent.length > 0) {
        if (typeof this.filters.intent == "string")
          params.intent = this.filters.intent;
        else params.intent = this.filters.intent.join(",");
      }
      if (this.filters.initiative_id) {
        params.initiative_id = this.filters.initiative_id;
      }
      if (this.filters.quick_reply_id) {
        params.quick_reply_id = this.filters.quick_reply_id;
      }
      if (this.filters.have_user_interation) {
        params.have_user_interation = this.filters.have_user_interation;
      }
      if (this.filters.without_initiatives) {
        params.without_initiatives = this.filters.without_initiatives;
      }
      return params;
    },
    getConversations() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let searchParams = this.createSearchParams();
          this.getConversationList(searchParams).then(() => {
            this.$router.push({ path: "", query: searchParams }).catch(() => {});
          });
        }
      });
    },
    openConversation(conversation_id) {
      let route = this.$router.resolve({
        path: `/customerService2/${this.currentService.id}/conversation/${conversation_id}`,
      });
      window.open(route.href, "_blank");
    },
    refreshPagination() {
      this.options.page = 1;
      this.options.sortBy = ["created_at"];
      this.options.sortDesc = [true];
    },
    filterMessages(sortBy = ["created_at"]) {
      this.filtersCount = this.filtersApllied.length;
      setTimeout(() => {
        this.getConversations();
      }, 500);
    },
    translateStatus(classValue) {
      switch (classValue) {
        case "AUTO":
          return "AUTO: Esta conversa está sendo gerenciada pelo rasa";

        case "PENDING":
          return "PENDING: Fila de pendentes";

        case "WAITING_TREATMENT":
          return "WAITING_TREATMENT: Fila de aguardando tratamento";

        case "WAITING_CUSTOMER":
          return "WAITING_CUSTOMER: Esperando uma resposta do cliente";

        case "CLOSING":
          return "CLOSING: Esperando alguma atividade antes de fechar a conversa";

        case "CLOSED":
          return "CLOSED: Atendimento encerrado";

        default:
          return classValue;
      }
    },

    remove(item, filter = "selectedAttendantsId", is_multi=true) {
      if (is_multi) {
        let itemId = item.id ? item.id : item.index;
        if (itemId >= 0)
          this.filters[filter].splice(this.filters[filter].indexOf(itemId), 1);
      } else {
        this.filters[filter] = null
      }
    },
    loadingCondition() {
      if (this.loading) return true;
      return false;
    },
    getQueryParams(queryParams) {
      this.options.page = queryParams.page ? parseInt(queryParams.page) : 1;
      this.options.itemsPerPage = queryParams.itemsPerPage
        ? parseInt(queryParams.itemsPerPage)
        : 25;
      if (queryParams.sortBy) {
        this.options.sortBy = [queryParams.sortBy];
        this.options.sortDesc = [queryParams.sortDesc == "true"];
      } else {
        this.options.sortBy = ["created_at"];
        this.options.sortDesc = [true];
      }
      if (queryParams.selectedAttendantsId)
        this.filters.selectedAttendantsId = queryParams.selectedAttendantsId
          .toString()
          .split(",")
          .map(Number);
      if (queryParams.selectStatus)
        this.filters.selectStatus = queryParams.selectStatus;
      if (queryParams.search) this.filters.search = queryParams.search;
      if (queryParams.customer_id) this.filters.customer_id = queryParams.customer_id;
      if (queryParams.startDate) this.filters.startDate = queryParams.startDate;
      if (queryParams.endDate) this.filters.endDate = queryParams.endDate;
      if (queryParams.intent)
        this.filters.intent = queryParams.intent
          .toString()
          .split(",");
      if (queryParams.bad_classification) this.filters.bad_classification = queryParams.bad_classification;
      if (queryParams.initiative_id) this.filters.initiative_id = queryParams.initiative_id;
      if (queryParams.quick_reply_id) this.filters.quick_reply_id = queryParams.quick_reply_id;
      if (queryParams.have_user_interation) this.filters.have_user_interation = queryParams.have_user_interation;
      if (queryParams.without_initiatives) this.filters.without_initiatives = queryParams.without_initiatives;
    },
    clearFilters() {
      this.filters.selectStatus = [];
      this.filters.selectedAttendantsId = [];
      this.filters.search = null;
      this.filters.customer_id = null;
      this.filtersApllied = [];
      this.filters.endDate = null;
      this.filters.startDate = null;
      this.filters.intent = null;
      this.filters.bad_classification = null;
      this.filters.initiative_id = null;
      this.filters.quick_reply_id = null;
      this.filters.have_user_interation = null;
      this.filters.without_initiatives = true;
      this.filtersCount = 0;
      this.refreshPagination();
    },
    translateMessageType(content_type, type, user_id) {
      if (content_type == 'text' && type == 'received') {
        return "Mensagem do cliente";

      } else if (content_type != 'text' && type == 'received') {
        return "Mídia do cliente";

      } else if (type == 'sent' && user_id) {
        return "Resposta do atendente";

      } else if (content_type == 'quick_reply' && type == 'sent') {
        return "Quick Reply do atendente";

      } else if (content_type == 'text' && type == 'sent' && !user_id) {
        return "Resposta do Rasa";
  
      } else if (content_type == 'initiative') {
        return "Iniciativa";

      } else {
        return null;
      }
    },
  },
  computed: {

    ...mapGetters("rasaConversationList", {
      messages: "list",
      totalItems: "total",
      loading: "loading",
      filters: "filters",
      intentsUntilNow: "intentsUntilNow",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
    ...mapGetters("rasaUsers", {
      usersList: "userList",
    }),
    ...mapGetters("rasaInitiative", {
      initiativesList: "list",
    }),
    ...mapGetters("rasaQuickReplyList", {
      quickReplyList: "list",
    }),
  },
  watch: {
    "filters.selectStatus"() {
      this.filters.selectStatus.length > 0 &&
      !this.filtersApllied.includes("selectStatus")
        ? this.filtersApllied.push("selectStatus")
        : null;
    },
    "filters.selectedAttendantsId"() {
      this.filters.selectedAttendantsId.length > 0 &&
      !this.filtersApllied.includes("selectedAttendantsId")
        ? this.filtersApllied.push("selectedAttendantsId")
        : null;
    },
    "filters.startDate"() {
      this.filters.startDate.length > 0 &&
      !this.filtersApllied.includes("startDate")
        ? this.filtersApllied.push("startDate")
        : null;
    },
    "filters.endDate"() {
      this.filters.endDate.length > 0 &&
      !this.filtersApllied.includes("endDate")
        ? this.filtersApllied.push("endDate")
        : null;
    },
    "filters.search"() {
      this.filters.search !== null && !this.filtersApllied.includes("search")
        ? this.filtersApllied.push("search")
        : null;
    },
    "filters.bad_classification"() {
      this.filters.bad_classification !== null && !this.filtersApllied.includes("bad_classification")
        ? this.filtersApllied.push("bad_classification")
        : null;
    },
    "filters.customer_id"() {
      this.filters.customer_id !== null && !this.filtersApllied.includes("customer_id")
        ? this.filtersApllied.push("customer_id")
        : null;
    },
    "filters.intent"() {
      this.filters.intent !== null && !this.filtersApllied.includes("intent")
        ? this.filtersApllied.push("intent")
        : null;
    },
    "filters.initiative_id"() {
      this.filters.initiative_id !== null && !this.filtersApllied.includes("initiative_id")
        ? this.filtersApllied.push("initiative_id")
        : null;
    },
    "filters.quick_reply_id"() {
      this.filters.quick_reply_id !== null && !this.filtersApllied.includes("quick_reply_id")
        ? this.filtersApllied.push("quick_reply_id")
        : null;
    },
    "filters.have_user_interation"() {
      this.filters.have_user_interation !== null && !this.filtersApllied.includes("have_user_interation")
        ? this.filtersApllied.push("have_user_interation")
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-conversation {
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 5px;
  height: 17px;
  line-height: 17px;
  width: 17px;
  padding: 5px;
  text-align: center;

  color: white;
  background-color: gray;

  &.PENDING {
    color: white;
    background-color: #2196f3;
  }

  &.AUTO {
    color: white;
    background-color: rgb(255, 0, 0);
  }

  &.WAITING_TREATMENT {
    color: white;
    background-color: #06f038;
  }

  &.WAITING_CUSTOMER {
    color: white;
    background-color: #34c6ff;
  }
  &.CLOSING {
    color: white;
    background-color: #ff8800;
  }
  &.CLOSE {
    color: white;
    background-color: #000000;
  }
}
.v-toolbar__content {
  padding: 2px;
}
.label-confidence {
  position: absolute;
  color: #767676;
}
a.fast-filters {
  white-space: nowrap;
}
.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__container {
  background-color: transparent !important;
}
.message {
  .row-message {
    .btn-isso {
      opacity: 0;
    }
    &:hover {
      .btn-isso {
        opacity: 1;
      }
    }
    // background-color: greenyellow;
  }
  .intent {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 300;
  }
  .confidence {
    font-size: 16px;
  }
  .intent-valid {
    visibility: hidden;
    &.valid {
      color: #4caf50;
      visibility: visible;
    }
    &.invalid {
      color: #f44336;
      visibility: visible;
    }
  }
  .intent-not-valid {
    font-size: 13px;
    font-weight: 500;
  }
  .avatar img {
    min-width: 21px;
    min-height: 21px;
  }
  .responsible-name {
    font-weight: 500;
  }
}


</style>